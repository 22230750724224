import React from 'react';
import { Bot, Users, FileText, MessageSquare } from 'lucide-react';
import Header from './Header';
import FeatureCard from './FeatureCard';
import FileUploader from './FileUpload/FileUploader';
import LoanRequest from './LoanRequest';
import LenderSearch from './LenderSearch';
import AdminPanel from './AdminPanel';
import LenderLogin from './auth/LenderLogin';
import LenderSignup from './auth/LenderSignup';
import LenderDashboard from './lender/LenderDashboard';
import { useAuthStore } from '../store/authStore';

type Section = 'upload' | 'search' | 'request' | 'admin' | 'login' | 'signup' | 'dashboard' | 'home';

interface ProtectedContentProps {
  activeSection: Section;
  setActiveSection: (section: Section) => void;
}

export default function ProtectedContent({ activeSection, setActiveSection }: ProtectedContentProps) {
  const { user, isAuthenticated } = useAuthStore();

  React.useEffect(() => {
    if (isAuthenticated && activeSection === 'login') {
      setActiveSection('dashboard');
    }
  }, [isAuthenticated, activeSection, setActiveSection]);

  const features = [
    {
      icon: Users,
      title: "Join our lender network",
      description: "Upload your rate sheet",
      section: 'upload' as const
    },
    {
      icon: FileText,
      title: "Help me find a lender",
      description: "Find the perfect loan based on your criteria",
      section: 'search' as const
    },
    {
      icon: MessageSquare,
      title: "Help me build a loan request",
      description: "Create a comprehensive loan package",
      section: 'request' as const
    }
  ];

  const handleNavigateHome = () => {
    setActiveSection('home');
  };

  const renderSection = () => {
    if (isAuthenticated && activeSection === 'dashboard') {
      return <LenderDashboard />;
    }

    switch (activeSection) {
      case 'upload':
        return <FileUploader onBack={handleNavigateHome} />;
      case 'search':
        return <LenderSearch onBack={handleNavigateHome} />;
      case 'request':
        return <LoanRequest onBack={handleNavigateHome} />;
      case 'admin':
        return <AdminPanel />;
      case 'login':
        return <LenderLogin 
          onBack={handleNavigateHome} 
          onSignup={() => setActiveSection('signup')}
        />;
      case 'signup':
        return <LenderSignup onBack={handleNavigateHome} />;
      default:
        return (
          <div className="flex-1 flex items-center justify-center py-8">
            <div className="max-w-7xl w-full px-6">
              <div className="text-center mb-16">
                <div className="inline-flex items-center justify-center space-x-3 mb-6">
                  <Bot size={40} className="text-blue-600" />
                  <p className="text-4xl md:text-5xl font-bold text-gray-900">
                    Hi, I'm RateRocket
                  </p>
                </div>
                <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-4">
                  Whether you're looking for the perfect loan or want to showcase your lending options, I'm here to assist every step of the way.
                </p>
                <p className="text-lg text-blue-600 mb-8">
                  No complex forms or manual data entry needed - just start a conversation!
                </p>
                <div className="flex justify-center space-x-4">
                  <button 
                    onClick={() => setActiveSection('search')}
                    className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    Find a Lender
                  </button>
                  <button 
                    onClick={() => setActiveSection('upload')}
                    className="px-6 py-3 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
                  >
                    I'm a Lender
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl mx-auto">
                {features.map((feature) => (
                  <FeatureCard
                    key={feature.title}
                    icon={feature.icon}
                    title={feature.title}
                    description={feature.description}
                    onClick={() => setActiveSection(feature.section)}
                  />
                ))}
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Header 
        onAdminClick={() => setActiveSection('admin')} 
        onLoginClick={() => setActiveSection('login')}
        isDashboard={isAuthenticated && activeSection === 'dashboard'}
        username={user?.firstName ? `${user.firstName} ${user.lastName}` : undefined}
      />
      <div className={`flex-1 flex flex-col ${activeSection === 'admin' ? '' : activeSection !== 'home' ? 'pt-0' : 'pt-16'}`}>
        {renderSection()}
      </div>
    </div>
  );
}