import React from 'react';
import { AlertCircle, AlertTriangle, Info } from 'lucide-react';

interface ErrorMessageProps {
  message: string;
  type: 'error' | 'warning' | 'info';
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, type }) => {
  const styles = {
    error: {
      bg: 'bg-red-50',
      border: 'border-red-400',
      text: 'text-red-700',
      icon: AlertCircle
    },
    warning: {
      bg: 'bg-yellow-50',
      border: 'border-yellow-400',
      text: 'text-yellow-700',
      icon: AlertTriangle
    },
    info: {
      bg: 'bg-blue-50',
      border: 'border-blue-400',
      text: 'text-blue-700',
      icon: Info
    }
  }[type];

  const Icon = styles.icon;

  return (
    <div className={`p-4 rounded-lg border ${styles.bg} ${styles.border} ${styles.text} flex items-start`}>
      <Icon className="h-5 w-5 flex-shrink-0 mr-3" />
      <span>{message}</span>
    </div>
  );
};

export default ErrorMessage;