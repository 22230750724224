import React from 'react';
import { Rocket, Building2, UserCircle2, ShieldCheck, User } from 'lucide-react';

interface HeaderProps {
  onAdminClick: () => void;
  onLoginClick?: () => void;
  isDashboard?: boolean;
  username?: string;
}

export default function Header({ onAdminClick, onLoginClick, isDashboard = false, username = 'RateRocket' }: HeaderProps) {
  const handleLogoClick = () => {
    window.history.pushState({}, '', '/');
    window.location.reload();
  };

  return (
    <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-lg z-50 border-b border-neutral-200/50">
      <div className="max-w-7xl mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <button 
            onClick={handleLogoClick}
            className="flex items-center space-x-3 group hover:opacity-90 transition-opacity"
          >
            <div className="bg-gradient-to-br from-primary-500 to-primary-600 p-2 rounded-xl group-hover:scale-105 transition-transform">
              <Rocket className="text-white" size={24} />
            </div>
            <h1 className="text-2xl font-bold bg-gradient-to-r from-primary-600 to-primary-800 bg-clip-text text-transparent">
              RateRocket
            </h1>
          </button>
          <div className="flex items-center space-x-6">
            {isDashboard ? (
              <div className="flex items-center space-x-3 text-neutral-600">
                <User size={20} className="text-gray-600" />
                <span className="font-medium">{username}</span>
              </div>
            ) : (
              <>
                <button
                  onClick={onLoginClick}
                  className="flex items-center space-x-2 text-neutral-600 hover:text-primary-600 transition-colors"
                >
                  <UserCircle2 size={20} />
                  <span className="font-medium">Lender Login</span>
                </button>
                <button
                  onClick={onAdminClick}
                  className="flex items-center space-x-2 text-neutral-600 hover:text-primary-600 transition-colors"
                >
                  <ShieldCheck size={20} />
                  <span className="font-medium">Admin</span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}