import React, { useState } from 'react';
import { ChevronRight, ChevronLeft } from 'lucide-react';

type Step = 'borrower' | 'property' | 'loan' | 'documents' | 'review';

export default function LoanOrigination() {
  const [currentStep, setCurrentStep] = useState<Step>('borrower');
  const [formData, setFormData] = useState({
    borrower: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      creditScore: '',
      annualIncome: ''
    },
    property: {
      address: '',
      type: '',
      purchasePrice: '',
      currentValue: '',
      useType: ''
    },
    loan: {
      amount: '',
      term: '',
      purpose: '',
      ltv: '',
      dscr: ''
    }
  });

  const steps: { id: Step; label: string }[] = [
    { id: 'borrower', label: 'Borrower Information' },
    { id: 'property', label: 'Property Details' },
    { id: 'loan', label: 'Loan Terms' },
    { id: 'documents', label: 'Required Documents' },
    { id: 'review', label: 'Review & Submit' }
  ];

  const handleInputChange = (section: keyof typeof formData, field: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const handleNext = () => {
    const currentIndex = steps.findIndex(step => step.id === currentStep);
    if (currentIndex < steps.length - 1) {
      setCurrentStep(steps[currentIndex + 1].id);
    }
  };

  const handlePrevious = () => {
    const currentIndex = steps.findIndex(step => step.id === currentStep);
    if (currentIndex > 0) {
      setCurrentStep(steps[currentIndex - 1].id);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      {/* Progress Steps */}
      <div className="mb-8">
        <div className="flex items-center justify-between">
          {steps.map((step, index) => (
            <React.Fragment key={step.id}>
              <div className="flex flex-col items-center">
                <div className={`
                  w-8 h-8 rounded-full flex items-center justify-center
                  ${currentStep === step.id 
                    ? 'bg-blue-600 text-white' 
                    : 'bg-gray-200 text-gray-600'}
                `}>
                  {index + 1}
                </div>
                <span className="mt-2 text-sm text-gray-600">{step.label}</span>
              </div>
              {index < steps.length - 1 && (
                <div className="flex-1 h-0.5 bg-gray-200 mx-4" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Form Content */}
      <div className="bg-white shadow rounded-lg p-6 mb-6">
        {currentStep === 'borrower' && (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  type="text"
                  value={formData.borrower.firstName}
                  onChange={(e) => handleInputChange('borrower', 'firstName', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  type="text"
                  value={formData.borrower.lastName}
                  onChange={(e) => handleInputChange('borrower', 'lastName', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  value={formData.borrower.email}
                  onChange={(e) => handleInputChange('borrower', 'email', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="tel"
                  value={formData.borrower.phone}
                  onChange={(e) => handleInputChange('borrower', 'phone', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Credit Score</label>
                <input
                  type="number"
                  value={formData.borrower.creditScore}
                  onChange={(e) => handleInputChange('borrower', 'creditScore', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Annual Income</label>
                <input
                  type="number"
                  value={formData.borrower.annualIncome}
                  onChange={(e) => handleInputChange('borrower', 'annualIncome', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>
        )}

        {currentStep === 'property' && (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Property Address</label>
              <input
                type="text"
                value={formData.property.address}
                onChange={(e) => handleInputChange('property', 'address', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Property Type</label>
                <select
                  value={formData.property.type}
                  onChange={(e) => handleInputChange('property', 'type', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="">Select type</option>
                  <option value="commercial">Commercial</option>
                  <option value="residential">Residential</option>
                  <option value="mixed">Mixed Use</option>
                  <option value="industrial">Industrial</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Use Type</label>
                <select
                  value={formData.property.useType}
                  onChange={(e) => handleInputChange('property', 'useType', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="">Select use</option>
                  <option value="owner">Owner Occupied</option>
                  <option value="investment">Investment</option>
                  <option value="development">Development</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Purchase Price</label>
                <input
                  type="number"
                  value={formData.property.purchasePrice}
                  onChange={(e) => handleInputChange('property', 'purchasePrice', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Current Value</label>
                <input
                  type="number"
                  value={formData.property.currentValue}
                  onChange={(e) => handleInputChange('property', 'currentValue', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>
        )}

        {currentStep === 'loan' && (
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Loan Amount</label>
                <input
                  type="number"
                  value={formData.loan.amount}
                  onChange={(e) => handleInputChange('loan', 'amount', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Loan Term (years)</label>
                <input
                  type="number"
                  value={formData.loan.term}
                  onChange={(e) => handleInputChange('loan', 'term', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Loan Purpose</label>
              <select
                value={formData.loan.purpose}
                onChange={(e) => handleInputChange('loan', 'purpose', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Select purpose</option>
                <option value="purchase">Purchase</option>
                <option value="refinance">Refinance</option>
                <option value="construction">Construction</option>
                <option value="bridge">Bridge</option>
              </select>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">LTV (%)</label>
                <input
                  type="number"
                  value={formData.loan.ltv}
                  onChange={(e) => handleInputChange('loan', 'ltv', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">DSCR</label>
                <input
                  type="number"
                  value={formData.loan.dscr}
                  onChange={(e) => handleInputChange('loan', 'dscr', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>
        )}

        {currentStep === 'documents' && (
          <div className="space-y-6">
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    Please prepare the following documents for upload:
                  </p>
                  <ul className="mt-2 ml-4 list-disc text-sm text-yellow-700">
                    <li>Property photos and recent appraisal (if available)</li>
                    <li>Last 2 years of tax returns</li>
                    <li>Last 3 months of bank statements</li>
                    <li>Current rent roll (if applicable)</li>
                    <li>Property financial statements</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center">
              <div className="text-gray-600">
                Document upload will be enabled after initial review
              </div>
            </div>
          </div>
        )}

        {currentStep === 'review' && (
          <div className="space-y-6">
            <div className="border rounded-lg p-4">
              <h3 className="font-medium text-gray-900 mb-4">Application Summary</h3>
              <dl className="grid grid-cols-2 gap-4">
                <div>
                  <dt className="text-sm font-medium text-gray-500">Borrower Name</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {formData.borrower.firstName} {formData.borrower.lastName}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Property Type</dt>
                  <dd className="mt-1 text-sm text-gray-900">{formData.property.type}</dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">Loan Amount</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    ${Number(formData.loan.amount).toLocaleString()}
                  </dd>
                </div>
                <div>
                  <dt className="text-sm font-medium text-gray-500">LTV</dt>
                  <dd className="mt-1 text-sm text-gray-900">{formData.loan.ltv}%</dd>
                </div>
              </dl>
            </div>
          </div>
        )}
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-between">
        <button
          onClick={handlePrevious}
          disabled={currentStep === 'borrower'}
          className="flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <ChevronLeft className="h-5 w-5 mr-2" />
          Previous
        </button>
        
        {currentStep === 'review' ? (
          <button
            onClick={() => {/* Handle submission */}}
            className="flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
          >
            Submit Application
          </button>
        ) : (
          <button
            onClick={handleNext}
            className="flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
          >
            Next
            <ChevronRight className="h-5 w-5 ml-2" />
          </button>
        )}
      </div>
    </div>
  );
}