import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface DataDisplayProps {
  title: string;
  content: string;
}

export default function DataDisplay({ title, content }: DataDisplayProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="flex items-center text-blue-600 hover:text-blue-700 text-sm font-medium"
        >
          {isExpanded ? (
            <>
              <ChevronUp className="h-4 w-4 mr-1" />
              Show Less
            </>
          ) : (
            <>
              <ChevronDown className="h-4 w-4 mr-1" />
              Show More
            </>
          )}
        </button>
      </div>
      <div className={`bg-gray-50 rounded-lg p-4 overflow-auto transition-all duration-300 ${
        isExpanded ? 'max-h-[600px]' : 'max-h-[200px]'
      }`}>
        <pre className="whitespace-pre-wrap text-sm text-gray-700 font-mono">
          {content}
        </pre>
      </div>
    </div>
  );
}