import { useState } from 'react';
import { 
  TrendingUp, 
  Building,
  FileText,
  MessageSquare,
  ChevronLeft,
  Menu
} from 'lucide-react';
import AdminDashboard from './AdminDashboard';
import LendersTable from './LendersTable';
import DocumentsTable from './DocumentsTable';
import InteractionHistory from './lender/InteractionHistory';
import Header from './Header';

type TabType = 'dashboard' | 'lenders' | 'documents' | 'interactions';

export default function AdminPanel() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [activeTab, setActiveTab] = useState<TabType>('dashboard');

  const tabs = [
    { id: 'dashboard', label: 'Dashboard', icon: TrendingUp },
    { id: 'lenders', label: 'Lenders', icon: Building },
    { id: 'documents', label: 'Documents', icon: FileText },
    { id: 'interactions', label: 'Interactions', icon: MessageSquare }
  ];

  const renderSection = () => {
    switch (activeTab) {
      case 'lenders':
        return <LendersTable />;
      case 'documents':
        return <DocumentsTable />;
      case 'interactions':
        return <InteractionHistory />;
      default:
        return <AdminDashboard />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header onAdminClick={() => {}} isDashboard={true} />
      
      <div className="flex h-[calc(100vh-4rem)] mt-16">
        <nav className={`
          fixed left-0 top-16 h-[calc(100vh-4rem)] bg-white border-r border-gray-200
          transition-all duration-300 z-40
          ${isNavOpen ? 'w-64' : 'w-16'}
        `}>
          <button 
            onClick={() => setIsNavOpen(!isNavOpen)}
            className="absolute right-0 top-4 transform translate-x-1/2 w-8 h-8 bg-white border border-gray-200 rounded-full flex items-center justify-center hover:bg-gray-50 transition-colors"
          >
            {isNavOpen ? <ChevronLeft size={20} /> : <Menu size={20} />}
          </button>

          <div className="mt-16">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id as TabType)}
                  className={`
                    w-full flex items-center px-4 py-3
                    transition-colors duration-200 relative
                    ${activeTab === tab.id 
                      ? 'text-blue-600 bg-blue-50' 
                      : 'text-gray-600 hover:bg-gray-50'}
                    ${!isNavOpen ? 'justify-center' : ''}
                  `}
                  title={!isNavOpen ? tab.label : undefined}
                >
                  <Icon size={20} className="flex-shrink-0" />
                  <span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${
                    isNavOpen ? 'opacity-100' : 'opacity-0 w-0 overflow-hidden'
                  }`}>
                    {tab.label}
                  </span>
                  {!isNavOpen && activeTab === tab.id && (
                    <div className="absolute right-0 top-0 bottom-0 w-1 bg-blue-600" />
                  )}
                </button>
              );
            })}
          </div>
        </nav>

        <main className={`flex-1 transition-all duration-300 ${isNavOpen ? 'ml-64' : 'ml-16'}`}>
          <div className="px-6 py-[40px]">
            <h1 className="text-2xl font-bold text-gray-900 mb-6">
              {tabs.find(tab => tab.id === activeTab)?.label}
            </h1>
            {renderSection()}
          </div>
        </main>
      </div>
    </div>
  );
}