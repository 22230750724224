import OpenAI from 'openai';
import { getEnvVar, validateOpenAIKey } from './env';

let openaiInstance: OpenAI | null = null;

export const getOpenAIInstance = (): OpenAI => {
  if (!openaiInstance) {
    const apiKey = validateOpenAIKey(getEnvVar('VITE_OPENAI_API_KEY'));
    
    openaiInstance = new OpenAI({
      apiKey,
      dangerouslyAllowBrowser: true
    });
  }
  return openaiInstance;
};

export const createChatCompletion = async (messages: any[]) => {
  try {
    const openai = getOpenAIInstance();
    
    const completion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages,
      temperature: 0.7,
      max_tokens: 1000,
    });

    return completion.choices[0].message;
  } catch (error: any) {
    console.error('OpenAI API Error:', error);
    
    if (error.status === 401) {
      throw new Error('Authentication failed. Please check your OpenAI API key.');
    }
    
    if (error.status === 429) {
      throw new Error('API rate limit exceeded. Please try again in a moment.');
    }
    
    if (error.status === 404) {
      throw new Error('The requested AI model is currently unavailable.');
    }

    throw new Error('Failed to get response from AI service. Please try again.');
  }
};