import React from 'react';
import ChatBot from './ChatBot';
import { Bot } from 'lucide-react';
import Breadcrumb from './Breadcrumb';

interface LenderSearchProps {
  onBack: () => void;
}

export default function LenderSearch({ onBack }: LenderSearchProps) {
  return (
    <div className="flex-1">
      <Breadcrumb
        items={[
          { label: 'Home', onClick: onBack },
          { label: 'Find a Lender' }
        ]}
      />
      <div className="max-w-4xl mx-auto p-6 mt-8">
        <div className="mb-12">
          <div className="text-center mb-8">
            <div className="flex items-center justify-center mb-4">
              <Bot size={32} className="text-blue-600 mr-2" />
              <h2 className="text-3xl font-bold text-blue-600">
                Find a Lender
              </h2>
            </div>
            <p className="text-xl text-gray-600 mb-4">
              I'll help you find the perfect lender by understanding your specific needs
              and matching you with the best available options.
            </p>
          </div>
        </div>

        <ChatBot 
          embedded={true} 
          mode="lender-search"
        />
      </div>
    </div>
  );
}