import { FileProcessingError } from '../errors/FileProcessingError';
import { FileType } from '../../types/file';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const ALLOWED_MIME_TYPES = {
  pdf: ['application/pdf'],
  word: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  excel: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ],
  text: ['text/plain', 'text/csv'],
  image: ['image/jpeg', 'image/png', 'image/webp']
};

export function validateFile(file: File, maxSize: number = MAX_FILE_SIZE): void {
  if (!file) {
    throw new FileProcessingError('No file provided');
  }

  if (file.size > maxSize) {
    throw new FileProcessingError(
      `File size exceeds ${maxSize / (1024 * 1024)}MB limit`
    );
  }

  const fileType = getFileType(file);
  if (!fileType) {
    throw new FileProcessingError(
      'Unsupported file type. Please upload a PDF, Word, Excel, text file, or image.'
    );
  }
}

export function getFileType(file: File): FileType | null {
  const mimeType = file.type.toLowerCase();
  
  for (const [type, mimeTypes] of Object.entries(ALLOWED_MIME_TYPES)) {
    if (mimeTypes.includes(mimeType)) {
      return type as FileType;
    }
  }

  // Check file extension as fallback
  const extension = file.name.toLowerCase().split('.').pop();
  if (extension) {
    if (['pdf'].includes(extension)) return 'pdf';
    if (['doc', 'docx'].includes(extension)) return 'word';
    if (['xls', 'xlsx', 'csv'].includes(extension)) return 'excel';
    if (['txt'].includes(extension)) return 'text';
    if (['jpg', 'jpeg', 'png', 'webp'].includes(extension)) return 'image';
  }

  return null;
}