export function getEnvVar(key: string, required: boolean = false): string | undefined {
  const value = import.meta.env[key];
  if (required && !value) {
    console.warn(`Environment variable ${key} is not set`);
  }
  return value;
}

export function validateOpenAIKey(key: string | undefined): string | undefined {
  if (!key) {
    console.warn('OpenAI API key is not configured');
    return undefined;
  }

  if (!key.startsWith('sk-')) {
    console.warn('Invalid OpenAI API key format');
    return undefined;
  }

  return key;
}

export function validatePineconeConfig(apiKey: string | undefined, environment: string | undefined): { apiKey?: string; environment?: string } {
  if (!apiKey) {
    console.warn('Pinecone API key is not configured');
  }

  if (!environment) {
    console.warn('Pinecone environment is not configured');
  }

  return { apiKey, environment };
}

export const config = {
  openai: {
    apiKey: validateOpenAIKey(getEnvVar('VITE_OPENAI_API_KEY', true))
  },
  pinecone: validatePineconeConfig(
    getEnvVar('VITE_PINECONE_API_KEY', true),
    getEnvVar('VITE_PINECONE_ENV', true)
  )
};
