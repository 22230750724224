import { ChatMessage } from '../types/chat';
import { RateSheet } from '../types/rateSheet';
import { getOpenAIInstance } from '../config/openai';

export class ChatError extends Error {
  constructor(
    message: string,
    public shouldRetry: boolean = false
  ) {
    super(message);
    this.name = 'ChatError';
  }
}

export async function processUserMessage(
  messages: ChatMessage[],
  rateSheets: RateSheet[],
  mode: 'lender-search' | 'loan-request'
): Promise<string> {
  const openai = getOpenAIInstance();
  if (!openai) {
    return "I'm currently unavailable. Please check your OpenAI API configuration.";
  }

  try {
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `You are a helpful AI assistant specializing in ${mode === 'lender-search' ? 'finding suitable lenders' : 'building loan request packages'}.`
        },
        ...messages.map(msg => ({
          role: msg.role,
          content: msg.content
        }))
      ],
      temperature: 0.7,
      max_tokens: 1000
    });

    return response.choices[0].message.content || "I apologize, but I couldn't generate a response. Please try again.";
  } catch (error: any) {
    console.error('OpenAI API Error:', error);
    
    if (error.status === 401) {
      throw new ChatError('Authentication failed. Please check the API configuration.');
    }
    
    if (error.status === 429) {
      throw new ChatError('The service is temporarily busy. Please try again in a moment.', true);
    }
    
    throw new ChatError('Failed to process your message. Please try again.');
  }
}