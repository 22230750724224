import { useState } from 'react';
import ProtectedContent from './components/ProtectedContent';
import PasswordProtection from './components/PasswordProtection';

type Section = 'upload' | 'search' | 'request' | 'admin' | 'login' | 'signup' | 'dashboard' | 'home';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => 
    localStorage.getItem('isAuthenticated') === 'true'
  );
  const [activeSection, setActiveSection] = useState<Section>('home');

  const handleAuthentication = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  if (!isAuthenticated) {
    return <PasswordProtection onAuthenticate={handleAuthentication} />;
  }

  return (
    <ProtectedContent 
      activeSection={activeSection} 
      setActiveSection={setActiveSection} 
    />
  );
}

export default App;