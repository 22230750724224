import { useState } from 'react';
import Header from '../Header';
import LenderNavigation from './LenderNavigation';
import LenderOverview from './LenderOverview';
import LoanOrigination from './LoanOrigination';
import LoanManagement from './LoanManagement';
import DocumentCenter from './DocumentCenter';
import InteractionHistory from './InteractionHistory';

type Section = 'overview' | 'origination' | 'management' | 'documents' | 'interactions';

export default function LenderDashboard() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  const [activeSection, setActiveSection] = useState<Section>('overview');

  const renderSection = () => {
    switch (activeSection) {
      case 'origination':
        return <LoanOrigination />;
      case 'management':
        return <LoanManagement />;
      case 'documents':
        return <DocumentCenter />;
      case 'interactions':
        return <InteractionHistory />;
      default:
        return <LenderOverview />;
    }
  };

  const getSectionTitle = (section: Section): string => {
    switch (section) {
      case 'overview':
        return 'Dashboard';
      case 'origination':
        return 'Loan Origination';
      case 'management':
        return 'Loan Management';
      case 'documents':
        return 'Document Center';
      case 'interactions':
        return 'Interactions';
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header onAdminClick={() => {}} isDashboard={true} username="Larry Lender" />
      
      <div className="flex h-[calc(100vh-4rem)] mt-16">
        <LenderNavigation 
          isOpen={isNavOpen}
          onToggle={() => setIsNavOpen(!isNavOpen)}
          activeSection={activeSection}
          onSectionChange={setActiveSection}
        />

        <main className={`flex-1 transition-all duration-300 ${isNavOpen ? 'ml-64' : 'ml-16'}`}>
          <div className="px-6 py-[40px]">
            <h1 className="text-2xl font-bold text-gray-900 mb-6">
              {getSectionTitle(activeSection)}
            </h1>
            {renderSection()}
          </div>
        </main>
      </div>
    </div>
  );
}