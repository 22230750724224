import { useState, useRef, useEffect } from 'react';
import { Send, Loader2, Bot } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { useRateSheetStore } from '../store/rateSheetStore';
import { useChatStore } from '../store/chatStore';
import { Link } from './Link';
import ErrorMessage from './ErrorMessage';
import { processUserMessage } from '../services/chatService';
import { ChatMessage } from '../types/chat';

interface ChatBotProps {
  embedded?: boolean;
  mode?: 'lender-search' | 'loan-request';
  initialMessage?: string;
}

export default function ChatBot({ mode = 'lender-search', initialMessage }: ChatBotProps) {
  const [input, setInput] = useState('');
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { messages, addMessage, isProcessing, setProcessing } = useChatStore();
  const { rateSheets } = useRateSheetStore();
  const conversationIdRef = useRef(`chat-${uuidv4()}`);
  const hasInitializedRef = useRef(false);

  useEffect(() => {
    if (hasInitializedRef.current) return;

    const defaultMessage = mode === 'lender-search'
      ? "Hi! Let's find the perfect lender. What type of property are you looking to finance?"
      : "Let's build your loan request package. What type of property are you looking to finance?";

    const message = initialMessage || defaultMessage;
    const conversationMessages = messages.filter(msg => msg.conversationId === conversationIdRef.current);

    if (conversationMessages.length === 0) {
      addMessage({
        role: 'assistant',
        content: message,
        timestamp: new Date().toISOString(),
        conversationId: conversationIdRef.current,
        mode
      });
      hasInitializedRef.current = true;
    }
  }, [mode, initialMessage, messages, addMessage]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const processResponse = async (userMessage: ChatMessage) => {
    try {
      const response = await processUserMessage(
        [...messages, userMessage],
        rateSheets,
        mode
      );

      addMessage({
        role: 'assistant',
        content: response,
        timestamp: new Date().toISOString(),
        conversationId: userMessage.conversationId,
        mode
      });
    } catch (error: any) {
      console.error('ChatBot error:', error);
      setError(error.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setProcessing(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || isProcessing) return;

    // Create message object
    const userMessage: ChatMessage = {
      role: 'user',
      content: input.trim(),
      timestamp: new Date().toISOString(),
      conversationId: conversationIdRef.current,
      mode
    };

    // Synchronously update UI state
    setInput('');
    setError(null);
    addMessage(userMessage);
    setProcessing(true);

    // Process response asynchronously
    setTimeout(() => {
      processResponse(userMessage);
    }, 0);
  };

  const conversationMessages = messages.filter(msg => msg.conversationId === conversationIdRef.current);

  return (
    <div className="flex flex-col h-[500px] bg-white rounded-lg shadow-md">
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {conversationMessages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.role === 'user' ? 'justify-end' : 'justify-start'
            }`}
          >
            <div
              className={`max-w-[80%] rounded-lg p-4 ${
                message.role === 'user'
                  ? 'bg-blue-500 text-white'
                  : 'bg-blue-50 text-gray-800'
              }`}
            >
              {message.role === 'assistant' && (
                <div className="flex items-start space-x-3">
                  <Bot size={24} className="text-blue-600 flex-shrink-0 mt-1" />
                  <div className="flex-1">
                    <p className="whitespace-pre-wrap">{message.content}</p>
                  </div>
                </div>
              )}
              {message.role === 'user' && (
                <div>
                  <p className="whitespace-pre-wrap">{message.content}</p>
                </div>
              )}
            </div>
          </div>
        ))}
        {isProcessing && (
          <div className="flex justify-start">
            <div className="bg-blue-50 rounded-lg p-4">
              <Loader2 className="h-5 w-5 animate-spin text-blue-500" />
            </div>
          </div>
        )}
        {error && (
          <ErrorMessage message={error} type="error" />
        )}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSubmit} className="p-4 border-t">
        <div className="flex space-x-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={isProcessing}
          />
          <button
            type="submit"
            disabled={isProcessing || !input.trim()}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Send className="h-5 w-5" />
          </button>
        </div>
      </form>

      <div className="px-4 py-3 bg-gray-50 text-center text-sm text-gray-600 space-y-2 rounded-b-lg">
        <p>RateRocket can make mistakes. Please check all information.</p>
        <p>
          By messaging RateRocket, you agree to our{' '}
          <Link href="/terms" className="text-blue-600 hover:underline">Terms</Link>
          {' '}and{' '}
          <Link href="/privacy" className="text-blue-600 hover:underline">Privacy Policy</Link>
        </p>
      </div>
    </div>
  );
}