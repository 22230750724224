import { FileProcessingError } from '../errors';
import mammoth from 'mammoth';

export async function parseWord(file: File): Promise<string> {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const buffer = Buffer.from(arrayBuffer); // Convert ArrayBuffer to Buffer
    const result = await mammoth.extractRawText({ buffer });

    if (!result.value.trim()) {
      throw new FileProcessingError('No readable text found in the Word document.');
    }

    return result.value.trim();
  } catch (error: any) {
    console.error('Word parsing error:', error);

    if (error instanceof FileProcessingError) {
      throw error;
    }

    throw new FileProcessingError(
      error.message?.includes('Invalid file')
        ? 'The Word document is invalid or corrupted.'
        : 'Failed to parse Word document. Please ensure the file is not corrupted.'
    );
  }
}
