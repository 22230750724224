import { FileProcessingError } from '../errors';
import { read, utils } from 'xlsx';

export async function parseExcel(file: File): Promise<string> {
  try {
    const arrayBuffer = await file.arrayBuffer();
    const workbook = read(arrayBuffer, { type: 'array' });
    
    if (!workbook.SheetNames.length) {
      throw new FileProcessingError('No sheets found in the Excel file.');
    }

    // Process all sheets and combine their content
    const sheetTexts = workbook.SheetNames.map((name: string) => {
      const sheet = workbook.Sheets[name];
      return `Sheet: ${name}\n${utils.sheet_to_txt(sheet)}`;
    });

    const combinedText = sheetTexts.join('\n\n').trim();
    
    if (!combinedText) {
      throw new FileProcessingError('No readable content found in the Excel file.');
    }
    
    return combinedText;
  } catch (error) {
    console.error('Excel parsing error:', error);

    if (error instanceof FileProcessingError) {
      throw error;
    }
    
    throw new FileProcessingError(
      error instanceof Error && error.message?.includes('Invalid')
        ? 'The Excel file is invalid or corrupted.'
        : 'Failed to parse Excel file. Please ensure the file is not corrupted.'
    );
  }
}
