import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: 'lender' | 'admin';
}

interface AuthState {
  user: User | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => boolean;
  logout: () => void;
}

// Test user credentials
const TEST_USER: User = {
  id: '1',
  firstName: 'Test',
  lastName: 'User',
  email: 'test@test.com',
  role: 'lender'
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      isAuthenticated: false,
      login: (email: string, password: string) => {
        if (email === 'test@test.com' && password === 'Password$21') {
          set({ user: TEST_USER, isAuthenticated: true });
          return true;
        }
        return false;
      },
      logout: () => set({ user: null, isAuthenticated: false })
    }),
    {
      name: 'auth-store',
      version: 1
    }
  )
);