import { FileProcessingError } from './errors/FileProcessingError';
import * as pdfjsLib from 'pdfjs-dist';
import { parsePDF } from './parsers/pdfParser';
import { parseWord } from './parsers/wordParser';
import { parseExcel } from './parsers/excelParser';
import { extractTextFromImage } from './extractors/imageExtractor';
import { validateFile, getFileType } from './validation/fileValidation';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

export async function processFile(file: File): Promise<string> {
  try {
    validateFile(file);
    const fileType = getFileType(file);

    if (!fileType) {
      throw new FileProcessingError('Unsupported file type');
    }

    let content: string;
    try {
      switch (fileType) {
        case 'pdf':
          content = await parsePDF(file);
          break;
        case 'word':
          content = await parseWord(file);
          break;
        case 'excel':
          content = await parseExcel(file);
          break;
        case 'text':
          content = await file.text();
          break;
        case 'image':
          content = await extractTextFromImage(file);
          break;
        default:
          throw new FileProcessingError('Unsupported file type');
      }
    } catch (processingError) {
      console.error('Processing error:', processingError);
      if (processingError instanceof FileProcessingError) {
        throw processingError;
      }
      throw new FileProcessingError(
        `Failed to process ${fileType.toUpperCase()} file. Please try a different format.`
      );
    }

    if (!content?.trim()) {
      throw new FileProcessingError(`No readable content found in ${fileType.toUpperCase()} file`);
    }

    return content.trim();
  } catch (error) {
    console.error('File processing error:', error);
    if (error instanceof FileProcessingError) {
      throw error;
    }
    throw new FileProcessingError('Failed to process file');
  }
}