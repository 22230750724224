import { useState } from 'react';
import { Search, Filter, Calendar, FileText, MessageSquare } from 'lucide-react';
import { formatDateTime } from '../../utils/dateUtils';
import { useRateSheetStore } from '../../store/rateSheetStore';
import { useChatStore } from '../../store/chatStore';
import { InteractionType } from '../../types/chat';
import { Link } from '../Link';

export default function InteractionHistory() {
  const [searchTerm, setSearchTerm] = useState('');
  const [typeFilter, setTypeFilter] = useState<InteractionType>('all');
  const { rateSheets } = useRateSheetStore();
  const { messages } = useChatStore();

  // Get first user messages from each conversation
  const firstUserMessages = Object.values(
    messages.reduce((acc: Record<string, typeof messages[0]>, msg) => {
      if (msg.role === 'user' && !acc[msg.conversationId]) {
        acc[msg.conversationId] = msg;
      }
      return acc;
    }, {})
  );

  // Combine file uploads and user chat messages
  const interactions = [
    ...rateSheets.map(sheet => ({
      id: sheet.id,
      type: 'file-upload' as const,
      content: sheet.filename,
      timestamp: sheet.uploadDate,
      user: sheet.lenderInfo.name || 'Unknown Lender',
      metadata: {
        fileId: sheet.id.slice(0, 8)
      }
    })),
    ...firstUserMessages.map(msg => ({
      id: msg.conversationId,
      type: msg.mode || 'lender-search', 
      content: msg.content,
      timestamp: msg.timestamp,
      user: 'User',
      metadata: {
        chatId: msg.conversationId.slice(0, 8)
      }
    }))
  ].sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

  const filteredInteractions = interactions.filter(interaction => {
    const matchesSearch = 
      interaction.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
      interaction.user.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = typeFilter === 'all' || interaction.type === typeFilter;
    return matchesSearch && matchesType;
  });

  const getInteractionColor = (type: string) => {
    switch (type) {
      case 'file-upload':
        return 'bg-green-100 text-green-800';
      case 'lender-search':
        return 'bg-blue-100 text-blue-800';
      case 'loan-request':
        return 'bg-purple-100 text-purple-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getInteractionLabel = (type: string) => {
    switch (type) {
      case 'file-upload':
        return 'File Upload';
      case 'lender-search':
        return 'Lender Search';
      case 'loan-request':
        return 'Loan Request';
      default:
        return type;
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex space-x-4">
        <div className="flex-1 relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            placeholder="Search interactions..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="relative">
          <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <select
            value={typeFilter}
            onChange={(e) => setTypeFilter(e.target.value as InteractionType)}
            className="pl-10 pr-8 py-2 border border-gray-300 rounded-lg appearance-none bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All Types</option>
            <option value="file-upload">File Uploads</option>
            <option value="lender-search">Lender Search</option>
            <option value="loan-request">Loan Request</option>
          </select>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Timestamp
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                User
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Content
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Details
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredInteractions.map((interaction) => (
              <tr key={`${interaction.type}-${interaction.id}`} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <div className="flex items-center">
                    <Calendar className="h-4 w-4 mr-1" />
                    {formatDateTime(interaction.timestamp)}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${getInteractionColor(interaction.type)}`}>
                    {getInteractionLabel(interaction.type)}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                  {interaction.user}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  <div className="max-w-lg truncate">
                    {interaction.content}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {interaction.type === 'file-upload' ? (
                    <div className="flex items-center space-x-2">
                      <FileText className="h-4 w-4 text-blue-500" />
                      <Link 
                        href={`/files/${interaction.metadata.fileId}`}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        {interaction.metadata.fileId}
                      </Link>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-2">
                      <MessageSquare className="h-4 w-4 text-blue-500" />
                      <Link 
                        href={`/chats/${interaction.metadata.chatId}`}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        {interaction.metadata.chatId}
                      </Link>
                    </div>
                  )}
                </td>
              </tr>
            ))}
            {filteredInteractions.length === 0 && (
              <tr>
                <td colSpan={5} className="px-6 py-8 text-center text-gray-500">
                  No interactions found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}