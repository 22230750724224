import React from 'react';
import { 
  LayoutDashboard, 
  FileSpreadsheet, 
  FolderOpen, 
  MessageSquare, 
  Files,
  ChevronLeft,
  Menu
} from 'lucide-react';

type Section = 'overview' | 'origination' | 'management' | 'documents' | 'interactions';

interface LenderNavigationProps {
  isOpen: boolean;
  onToggle: () => void;
  activeSection: Section;
  onSectionChange: (section: Section) => void;
}

export default function LenderNavigation({ 
  isOpen, 
  onToggle, 
  activeSection, 
  onSectionChange 
}: LenderNavigationProps) {
  const menuItems = [
    { id: 'overview' as Section, label: 'Dashboard', icon: LayoutDashboard },
    { id: 'origination' as Section, label: 'Origination', icon: FileSpreadsheet },
    { id: 'management' as Section, label: 'Management', icon: FolderOpen },
    { id: 'documents' as Section, label: 'Documents', icon: Files },
    { id: 'interactions' as Section, label: 'Interactions', icon: MessageSquare },
  ];

  return (
    <nav className={`
      fixed left-0 top-16 h-[calc(100vh-4rem)] bg-white border-r border-gray-200
      transition-all duration-300 z-40
      ${isOpen ? 'w-64' : 'w-16'}
    `}>
      <button 
        onClick={onToggle}
        className="absolute right-0 top-4 transform translate-x-1/2 w-8 h-8 bg-white border border-gray-200 rounded-full flex items-center justify-center hover:bg-gray-50 transition-colors"
      >
        {isOpen ? <ChevronLeft size={20} /> : <Menu size={20} />}
      </button>

      <div className="mt-16">
        {menuItems.map((item) => {
          const Icon = item.icon;
          return (
            <button
              key={item.id}
              onClick={() => onSectionChange(item.id)}
              className={`
                w-full flex items-center px-4 py-3
                transition-colors duration-200 relative
                ${activeSection === item.id 
                  ? 'text-blue-600 bg-blue-50' 
                  : 'text-gray-600 hover:bg-gray-50'}
                ${!isOpen ? 'justify-center' : ''}
              `}
              title={!isOpen ? item.label : undefined}
            >
              <Icon size={20} className="flex-shrink-0" />
              <span className={`ml-3 whitespace-nowrap transition-opacity duration-300 ${
                isOpen ? 'opacity-100' : 'opacity-0 w-0 overflow-hidden'
              }`}>
                {item.label}
              </span>
              {!isOpen && activeSection === item.id && (
                <div className="absolute right-0 top-0 bottom-0 w-1 bg-blue-600" />
              )}
            </button>
          );
        })}
      </div>
    </nav>
  );
}