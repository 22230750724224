import React, { useState, useMemo } from 'react';
import { useRateSheetStore } from '../store/rateSheetStore';
import { ArrowUpDown, ChevronUp, ChevronDown, FileText, Search, Trash2, Download } from 'lucide-react';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { RateSheet } from '../types/rateSheet';

type SortField = keyof Required<RateSheet['parsedData']> | 'lenderName' | 'contactInfo';
type SortDirection = 'asc' | 'desc';

export default function LendersTable() {
  const { rateSheets, removeRateSheet } = useRateSheetStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<SortField>('lenderName');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<{ id: string; filename: string } | null>(null);

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const handleDeleteClick = (id: string, filename: string) => {
    setFileToDelete({ id, filename });
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (fileToDelete) {
      removeRateSheet(fileToDelete.id);
      setDeleteModalOpen(false);
      setFileToDelete(null);
    }
  };

  const sortedData = useMemo(() => {
    return [...rateSheets]
      .filter(sheet => {
        const searchStr = searchTerm.toLowerCase();
        return (
          sheet.lenderInfo.name.toLowerCase().includes(searchStr) ||
          sheet.lenderInfo.state.toLowerCase().includes(searchStr)
        );
      })
      .sort((a, b) => {
        let aValue: string = '';
        let bValue: string = '';

        switch (sortField) {
          case 'lenderName':
            aValue = a.lenderInfo.name;
            bValue = b.lenderInfo.name;
            break;
          case 'contactInfo':
            aValue = `${a.lenderInfo.contactInfo.email || ''} ${a.lenderInfo.contactInfo.phone || ''}`;
            bValue = `${b.lenderInfo.contactInfo.email || ''} ${b.lenderInfo.contactInfo.phone || ''}`;
            break;
          default:
            aValue = String(a.parsedData[sortField] || '');
            bValue = String(b.parsedData[sortField] || '');
        }

        return sortDirection === 'asc' 
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      });
  }, [rateSheets, searchTerm, sortField, sortDirection]);

  return (
    <div className="space-y-4">
      <div className="flex space-x-4">
        <div className="flex-1 relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          <input
            type="text"
            placeholder="Search lenders..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <div className="flex items-center space-x-1 cursor-pointer" onClick={() => handleSort('lenderName')}>
                  <span>Lender Name</span>
                  {sortField === 'lenderName' ? (
                    sortDirection === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />
                  ) : (
                    <ArrowUpDown size={16} />
                  )}
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                State
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Upload Date
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedData.map((sheet) => (
              <tr key={sheet.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <FileText className="h-5 w-5 text-gray-400 mr-2" />
                    <span className="font-medium text-gray-900">{sheet.lenderInfo.name}</span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {sheet.lenderInfo.state}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {new Date(sheet.uploadDate).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-center">
                  <div className="flex justify-center space-x-2">
                    <button
                      onClick={() => {/* Handle download */}}
                      className="text-blue-600 hover:text-blue-800"
                      title="Download"
                    >
                      <Download className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDeleteClick(sheet.id, sheet.filename)}
                      className="text-red-600 hover:text-red-800"
                      title="Delete"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            {sortedData.length === 0 && (
              <tr>
                <td colSpan={4} className="px-6 py-8 text-center text-gray-500">
                  No lenders found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setFileToDelete(null);
        }}
        onConfirm={handleConfirmDelete}
        itemName={fileToDelete?.filename || ''}
      />
    </div>
  );
}