import { ChevronRight, Home } from 'lucide-react';

interface BreadcrumbProps {
  items: Array<{
    label: string;
    onClick?: () => void;
  }>;
}

export default function Breadcrumb({ items }: BreadcrumbProps) {
  return (
    <nav className="bg-gray-50 border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex items-center h-14">
          <ol className="flex items-center space-x-2">
            <li>
              <button
                onClick={() => items[0].onClick?.()}
                className="text-gray-500 hover:text-blue-600 flex items-center"
              >
                <Home className="h-4 w-4" />
              </button>
            </li>
            {items.map((item, index) => (
              <li key={item.label} className="flex items-center">
                <ChevronRight className="h-4 w-4 text-gray-400 mx-2" />
                <button
                  onClick={item.onClick}
                  className={`${
                    index === items.length - 1
                      ? 'text-gray-800 font-medium'
                      : 'text-gray-500 hover:text-blue-600'
                  }`}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ol>
        </div>
      </div>
    </nav>
  );
}