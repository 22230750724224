export class FileProcessingError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FileProcessingError';
  }
}

export class FileValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FileValidationError';
  }
}

export class ExtractorError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ExtractorError';
  }
}

export class FileParsingError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'FileParsingError';
  }
}
