import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { ChatMessage } from '../types/chat';

interface ChatStore {
  messages: ChatMessage[];
  isProcessing: boolean;
  retryCount: number;
  maxRetries: number;
  addMessage: (message: ChatMessage) => void;
  setProcessing: (status: boolean) => void;
  incrementRetry: () => void;
  resetRetry: () => void;
  clearMessages: () => void;
}

const CURRENT_VERSION = 1;

export const useChatStore = create<ChatStore>()(
  persist(
    (set) => ({
      messages: [],
      isProcessing: false,
      retryCount: 0,
      maxRetries: 3,
      addMessage: (message) => set((state) => ({ 
        messages: [...state.messages, {
          ...message,
          timestamp: message.timestamp || new Date().toISOString(),
          conversationId: message.conversationId || `chat-${Date.now()}`
        }]
      })),
      setProcessing: (status) => set({ isProcessing: status }),
      incrementRetry: () => set((state) => ({ 
        retryCount: state.retryCount + 1 
      })),
      resetRetry: () => set({ retryCount: 0 }),
      clearMessages: () => set({ messages: [] })
    }),
    {
      name: 'chat-store',
      version: CURRENT_VERSION,
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        messages: state.messages
      }),
      merge: (persistedState: any, currentState) => {
        const messages = Array.isArray(persistedState?.messages) 
          ? persistedState.messages 
          : [];

        return {
          ...currentState,
          messages: messages.map((msg: any) => ({
            ...msg,
            timestamp: msg.timestamp || new Date().toISOString(),
            conversationId: msg.conversationId || `chat-${Date.now()}`
          }))
        };
      }
    }
  )
);