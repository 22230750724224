import React from 'react';
import { LucideIcon } from 'lucide-react';

interface FeatureCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
  onClick: () => void;
}

export default function FeatureCard({ icon: Icon, title, description, onClick }: FeatureCardProps) {
  return (
    <button
      onClick={onClick}
      className="feature-card w-full text-left group hover:scale-[1.02] hover:-translate-y-1"
    >
      <div className="icon-circle mb-6 group-hover:scale-110 transition-transform duration-300">
        <Icon size={32} />
      </div>
      <h3 className="text-2xl font-semibold text-neutral-900 mb-3 whitespace-nowrap">
        {title}
      </h3>
      <p className="text-neutral-600 whitespace-nowrap">
        {description}
      </p>
    </button>
  );
}