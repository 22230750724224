import React, { useState } from 'react';
import { 
  Files, 
  Users, 
  MessageSquare, 
  TrendingUp,
  TrendingDown,
  MapPin,
  Calendar,
  Trash2,
  HardDrive,
  FileText
} from 'lucide-react';
import { useRateSheetStore } from '../store/rateSheetStore';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { formatDateTime } from '../utils/dateUtils';

export default function AdminDashboard() {
  const { rateSheets, removeRateSheet } = useRateSheetStore();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<{ id: string; filename: string } | null>(null);

  const stats = {
    totalFiles: rateSheets.length,
    totalStates: TOP_STATES.length,
    totalInteractions: TOP_STATES.reduce((sum, state) => sum + state.activity, 0),
    activeUsers: Math.floor(TOP_STATES.reduce((sum, state) => sum + state.activity, 0) / 10)
  };

  const handleDeleteClick = (id: string, filename: string) => {
    setFileToDelete({ id, filename });
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (fileToDelete) {
      removeRateSheet(fileToDelete.id);
      setDeleteModalOpen(false);
      setFileToDelete(null);
    }
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  // Sort rate sheets by upload date (newest first)
  const latestFiles = [...rateSheets]
    .sort((a, b) => new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime())
    .slice(0, 5);

  return (
    <div className="space-y-6">
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <div className="bg-blue-50 rounded-lg p-3">
              <Files className="h-6 w-6 text-blue-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Total Files</p>
              <p className="text-2xl font-semibold text-gray-900">{stats.totalFiles}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <div className="bg-green-50 rounded-lg p-3">
              <Users className="h-6 w-6 text-green-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Active States</p>
              <p className="text-2xl font-semibold text-gray-900">{stats.totalStates}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <div className="bg-purple-50 rounded-lg p-3">
              <MessageSquare className="h-6 w-6 text-purple-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Total Interactions</p>
              <p className="text-2xl font-semibold text-gray-900">{stats.totalInteractions}</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center">
            <div className="bg-orange-50 rounded-lg p-3">
              <Users className="h-6 w-6 text-orange-600" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-500">Active Users</p>
              <p className="text-2xl font-semibold text-gray-900">{stats.activeUsers}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* State Activity Grid */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <MapPin className="h-5 w-5 text-blue-500 mr-2" />
            Top 20 States by Activity
          </h2>
          <div className="grid grid-cols-5 gap-3">
            {TOP_STATES.map((state) => (
              <div
                key={state.state}
                className="bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div className="space-y-1">
                    <div className="text-base font-bold text-gray-900">{state.state}</div>
                    <div className="text-sm text-gray-600">{state.activity}</div>
                  </div>
                  {state.trend === 'up' ? (
                    <TrendingUp className="h-4 w-4 text-green-500 mt-1" />
                  ) : (
                    <TrendingDown className="h-4 w-4 text-red-500 mt-1" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Latest Files */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <FileText className="h-5 w-5 text-blue-500 mr-2" />
            Latest Uploads
          </h2>
          <div className="space-y-4">
            {latestFiles.map((file) => (
              <div
                key={file.id}
                className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-lg transition-colors"
              >
                <div className="flex items-center space-x-3">
                  <FileText className="h-5 w-5 text-gray-400" />
                  <div>
                    <a
                      href={`/rate-sheets/${file.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-medium text-gray-900 hover:text-blue-600 transition-colors"
                    >
                      {file.filename}
                    </a>
                    <div className="flex items-center space-x-2 text-sm text-gray-500">
                      <Calendar className="h-4 w-4" />
                      <span>{formatDateTime(file.uploadDate)}</span>
                      <span className="text-gray-400">•</span>
                      <div className="flex items-center space-x-1">
                        <HardDrive className="h-4 w-4" />
                        <span>{formatFileSize(file.size)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => handleDeleteClick(file.id, file.filename)}
                  className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                  title="Delete file"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            ))}
            {latestFiles.length === 0 && (
              <div className="text-center py-8 text-gray-500">
                No files uploaded yet
              </div>
            )}
          </div>
        </div>
      </div>

      <DeleteConfirmationModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setFileToDelete(null);
        }}
        onConfirm={handleConfirmDelete}
        itemName={fileToDelete?.filename || ''}
      />
    </div>
  );
}

const TOP_STATES = [
  { state: 'CA', activity: 156, trend: 'up' },
  { state: 'TX', activity: 142, trend: 'up' },
  { state: 'FL', activity: 134, trend: 'up' },
  { state: 'NY', activity: 128, trend: 'down' },
  { state: 'IL', activity: 112, trend: 'up' },
  { state: 'PA', activity: 98, trend: 'down' },
  { state: 'OH', activity: 92, trend: 'up' },
  { state: 'GA', activity: 88, trend: 'up' },
  { state: 'NC', activity: 86, trend: 'up' },
  { state: 'MI', activity: 84, trend: 'down' },
  { state: 'NJ', activity: 82, trend: 'up' },
  { state: 'VA', activity: 78, trend: 'up' },
  { state: 'WA', activity: 76, trend: 'down' },
  { state: 'AZ', activity: 74, trend: 'up' },
  { state: 'MA', activity: 72, trend: 'down' },
  { state: 'TN', activity: 70, trend: 'up' },
  { state: 'IN', activity: 68, trend: 'down' },
  { state: 'MO', activity: 66, trend: 'up' },
  { state: 'MD', activity: 64, trend: 'down' },
  { state: 'WI', activity: 62, trend: 'up' }
];