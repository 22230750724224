import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FileProcessingError } from '../utils/errors/FileProcessingError';
import { processFile } from '../utils/fileProcessor';
import { useRateSheetStore } from '../store/rateSheetStore';
import { RateSheet } from '../types/rateSheet';

interface ProcessingState {
  isProcessing: boolean;
  error: string | null;
}

export function useFileProcessor() {
  const [processingState, setProcessingState] = useState<ProcessingState>({
    isProcessing: false,
    error: null
  });

  const { addRateSheet } = useRateSheetStore();

  const processUploadedFile = async (file: File): Promise<RateSheet | null> => {
    setProcessingState({ isProcessing: true, error: null });

    try {
      const content = await processFile(file);
      
      const rateSheet: RateSheet = {
        id: uuidv4(),
        filename: file.name,
        content,
        uploadDate: new Date().toISOString(),
        size: file.size,
        lenderInfo: {
          name: 'Unknown',
          state: 'Unknown',
          programs: [],
          rates: {},
          contactInfo: {}
        },
        parsedData: {
          uploadTimestamp: new Date().toISOString()
        }
      };

      addRateSheet(rateSheet);
      setProcessingState({ isProcessing: false, error: null });
      return rateSheet;
    } catch (error) {
      const message = error instanceof FileProcessingError 
        ? error.message 
        : 'Failed to process file';
        
      setProcessingState({ isProcessing: false, error: message });
      return null;
    }
  };

  return {
    processingState,
    processUploadedFile
  };
}