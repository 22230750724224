import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, AlertCircle, CheckCircle, X, Bot, Lock, MessageSquare, Loader2 } from 'lucide-react';
import { useRateSheetStore } from '../../store/rateSheetStore';
import { Link } from '../Link';
import ChatBot from '../ChatBot';
import DataDisplay from './DataDisplay';
import { useFileProcessor } from '../../hooks/useFileProcessor';
import Breadcrumb from '../Breadcrumb';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

interface FileUploaderProps {
  onBack: () => void;
}

export default function FileUploader({ onBack }: FileUploaderProps) {
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [extractedContent, setExtractedContent] = useState<string | null>(null);
  
  const { processingState, processUploadedFile } = useFileProcessor();
  const { isProcessing, error } = processingState;

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!acceptedTerms) {
      return;
    }

    if (acceptedFiles.length === 0) {
      return;
    }

    const file = acceptedFiles[0];
    const rateSheet = await processUploadedFile(file);

    if (rateSheet) {
      setExtractedContent(rateSheet.content);
      setShowChat(true);
    }
  }, [acceptedTerms, processUploadedFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: !acceptedTerms || isProcessing,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/plain': ['.txt'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/webp': ['.webp']
    },
    maxFiles: 1,
    maxSize: MAX_FILE_SIZE
  });

  return (
    <div className="flex-1">
      <Breadcrumb
        items={[
          { label: 'Home', onClick: onBack },
          { label: 'Upload Rate Sheet' }
        ]}
      />
      <div className="max-w-4xl mx-auto p-6 mt-12">
        <div className="mb-12">
          <div className="text-center mb-8">
            <div className="flex items-center justify-center mb-4">
              <Bot size={32} className="text-blue-600 mr-2" />
              <h2 className="text-3xl font-bold text-blue-600">
                Upload Your Rate Sheet
              </h2>
            </div>
            <div className="text-xl text-gray-600">
              <p>Don't be left behind your competitors by using traditional origination methods.</p>
              <p>Let me help you reach more borrowers instantly.</p>
            </div>
          </div>
        </div>

        <div className="mb-6">
          <div className="bg-blue-50 border border-blue-100 rounded-lg p-4">
            <label className="flex items-start space-x-3">
              <input
                type="checkbox"
                checked={acceptedTerms}
                onChange={(e) => setAcceptedTerms(e.target.checked)}
                className="mt-1 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <span className="text-sm text-gray-600">
                By uploading your rate sheet, you agree to RateRocket's {' '}
                <Link href="/terms" className="text-blue-600 hover:underline">Terms of Service</Link>
                {' '}and{' '}
                <Link href="/privacy" className="text-blue-600 hover:underline">Privacy Policy</Link>
              </span>
            </label>
          </div>
        </div>

        <div className="relative">
          <div
            {...getRootProps()}
            className={`
              relative border-2 border-dashed rounded-lg p-8 text-center transition-colors
              ${!acceptedTerms || isProcessing ? 'opacity-50 cursor-not-allowed border-gray-300' : isDragActive ? 'border-blue-500 bg-blue-50 cursor-pointer' : 'border-gray-300 hover:border-blue-400 cursor-pointer'}
            `}
          >
            <input {...getInputProps()} />
            {!acceptedTerms && (
              <div className="absolute inset-0 bg-gray-50/50 backdrop-blur-[1px] flex items-center justify-center rounded-lg">
                <div className="flex items-center space-x-2 text-gray-600">
                  <Lock className="h-5 w-5" />
                  <span>Accept terms above to unlock upload</span>
                </div>
              </div>
            )}
            {isProcessing ? (
              <div className="flex flex-col items-center">
                <Loader2 className="h-12 w-12 mb-4 text-blue-500 animate-spin" />
                <p className="text-lg text-gray-700">Processing your file...</p>
              </div>
            ) : (
              <>
                <Upload className={`mx-auto h-12 w-12 mb-4 ${!acceptedTerms ? 'text-gray-400' : 'text-blue-500'}`} />
                <p className={`text-lg mb-2 ${!acceptedTerms ? 'text-gray-400' : 'text-gray-700'}`}>
                  {isDragActive ? 'Drop your file here' : 'Upload your rate sheet'}
                </p>
                <p className={`text-sm ${!acceptedTerms ? 'text-gray-400' : 'text-gray-500'}`}>
                  Supports PDF, Word, CSV, Excel, and text files as well as images (max 10MB)
                </p>
              </>
            )}
          </div>
        </div>

        {extractedContent && (
          <div className="mt-8 mb-8">
            <DataDisplay 
              title="Extracted Content Preview" 
              content={extractedContent} 
            />
          </div>
        )}

        {showChat && (
          <div className="mt-8">
            <div className="bg-green-50 rounded-lg p-4 mb-6 flex items-center gap-2 text-green-700">
              <CheckCircle className="h-5 w-5 flex-shrink-0" />
              <span>File uploaded successfully! Let me help you review and complete the information.</span>
            </div>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex items-center space-x-3 mb-4">
                <MessageSquare className="h-6 w-6 text-blue-600" />
                <h3 className="text-lg font-semibold">Let's Review Your Information</h3>
              </div>
              <ChatBot 
                embedded={true}
                initialMessage="I've analyzed your rate sheet. Let me help you verify and complete any missing information. What's your primary lending area?"
              />
            </div>
          </div>
        )}

        {error && (
          <div className="mt-4 p-4 bg-red-50 rounded-lg flex items-center gap-2 text-red-700">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            <span>{error}</span>
            <button 
              onClick={() => setExtractedContent(null)}
              className="ml-auto"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}