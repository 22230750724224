import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { RateSheet } from '../types/rateSheet';

interface RateSheetStore {
  rateSheets: RateSheet[];
  addRateSheet: (rateSheet: RateSheet) => void;
  removeRateSheet: (id: string) => void;
  getAllSheets: () => RateSheet[];
  getRateSheetsByState: (state: string) => RateSheet[];
}

export const useRateSheetStore = create<RateSheetStore>()(
  persist(
    (set, get) => ({
      rateSheets: [],
      addRateSheet: (rateSheet) => 
        set((state) => ({ 
          rateSheets: [...state.rateSheets, rateSheet]
        })),
      removeRateSheet: (id) =>
        set((state) => ({
          rateSheets: state.rateSheets.filter(sheet => sheet.id !== id)
        })),
      getAllSheets: () => get().rateSheets,
      getRateSheetsByState: (state) => 
        get().rateSheets.filter(sheet => 
          sheet.lenderInfo.state.toLowerCase() === state.toLowerCase()
        )
    }),
    {
      name: 'rate-sheet-store',
      version: 1
    }
  )
);