import React from 'react';
import ChatBot from './ChatBot';
import { Bot } from 'lucide-react';
import Breadcrumb from './Breadcrumb';

interface LoanRequestProps {
  onBack: () => void;
}

export default function LoanRequest({ onBack }: LoanRequestProps) {
  return (
    <div className="flex-1">
      <Breadcrumb
        items={[
          { label: 'Home', onClick: onBack },
          { label: 'Build Loan Package' }
        ]}
      />
      <div className="max-w-4xl mx-auto p-6">
        <div className="text-center mb-8">
          <div className="flex items-center justify-center mb-4">
            <Bot size={32} className="text-blue-600 mr-2" />
            <h2 className="text-3xl font-bold text-gray-900">Build Your Loan Package</h2>
          </div>
          <p className="text-gray-600">
            Let me guide you through creating a comprehensive loan request package.
            I'll help you gather all the necessary information and documentation.
          </p>
        </div>
        <ChatBot embedded={true} mode="loan-request" />
      </div>
    </div>
  );
}